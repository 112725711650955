@import "./simple-loder";

.loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: 100;

  &--hide {
    transition: opacity 0.3s ease-in;
    opacity: 0;
  }

  &--zindex {
    z-index: -1;
  }

  img {
    width: max(25vw, 200px);
  }
}
