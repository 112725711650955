.check-box {
  position: relative;
  display: inline-block;
  
  &__field {
    margin-right: 10px;
  }

  &__error {
    width: max-content;
    font-size: 12px;
    color: #f5222d;
    position: absolute;
    bottom: -20px;
    left: 0;
    z-index: 1;
  }
}
