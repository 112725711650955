.react-datepicker__input-container {
  position: relative;
  
  input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &__error {
    font-size: 12px;
    color: #f5222d;
    position: absolute;
    bottom: -20px;
    left: 10px;
    z-index: 1;
  }
}
