/*!
*
* Template: Hope-Ui - Responsive Bootstrap 5 Admin Dashboard Template
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for responsive Template.
*
*/

// Ant Design
@import 'antd/lib/modal/style/index.css';
@import 'antd/lib/notification/style/index.css';
@import 'antd/lib/button/style/index.css';
@import './custom/air-datepicker.css';

@import "./custom/helper/fonts";
@import "bootstrap/scss/bootstrap";

// Variables
@import "./custom/variable";
@import "./custom/variables/index";

// Custom Mixin And Helper
@import "./custom/helper/functions";
@import "./custom/helper/mixins";
@import "./custom/helper/reboot";

// // Extra Components and Utilities and plugins
@import "./custom/components/components";
@import "./custom/layout-style/index";
@import "./custom/helper/utilities";
@import "./custom/plugins/plugins";

@import "./custom/rtl/index";

@import "./custom/pages/auth/authentication";
@import "./custom/pages/kanban/kanban";
@import "./custom/pages/pricing/pricing";
@import "./custom/pages/ui-kit/ui-kit";

@import "./custom/dark/index";