.iq-maintenance {
    position: fixed;
    bottom: 0;
    width: 100%;
    img {
        max-height: 350px;
    }
    .maintenance-bottom {
        width: 100%;
        text-align: center;
        padding: 0;

        .search-input {
            input {
                &.form-control {
                    background: transparent;
                    border-radius: .5rem !important;
                }
            }
        }
        .bottom-text{
            position: absolute;
            left:0;
            right:0;
            bottom: 40px;
           
        }
    }
}

.error {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
}